<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-10 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="lineList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
                    {{ scope.row.active ? 'Activo' : 'Inactivo' }}
                  </p>
                </div>
                <div class="is-flex is-justify-content-center is-align-items-center" slot="action" slot-scope="scope">
                  <router-link :to="`/productos/lineas/${scope.row.id}/detalle`">
                    <img class="action_img" src="@/assets/icon/eye_icon.png" alt="Editar">
                  </router-link>
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-10">
            <base-pagination
              :page="page"
              :pages="parseInt(pages)"
              :action="paginator"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
const DEFAULT_DISPLAY_SIZE = 50

export default {
  name: 'MainLines',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue')
  },
  data () {
    return {
      loading: false,
      page: 1,
      pages: 1,
      columnsData: [
        { id: 'id', header: 'ID' },
        { id: 'product_line_name', header: 'Línea' },
        { id: 'created_at', header: 'Fecha de creación', accessor: (row) => moment(row.created_at).format('DD/MMM/YYYY') },
        { id: 'status', name: 'status', header: 'Estatus' },
        { id: 'action', name: 'action', header: 'Acciones', class: 'has-text-centered' }
      ],
      lineList: []
    }
  },
  methods: {
    ...mapActions(['getProductLines']),
    async getLinesList (page) {
      const resp = await this.getProductLines({ page: page, limit: DEFAULT_DISPLAY_SIZE })
      this.pages = resp.totalPages
      this.lineList = resp.lines
      this.loading = false
    },
    paginator (page) {
      this.loading = true
      this.page = page
      this.getLinesList(this.page)
    }
  },
  beforeMount () {
    this.loading = true
    this.getLinesList(this.page)
  }
}
</script>

<style lang="scss" scoped>
  .action_img {
    width: 20px;
    margin: auto;
    cursor: pointer;
  }
</style>
